<template>
    <vue-bottom-sheet ref="mobileBottomSheet" max-height="100%" :overlay="true" class="mobile-modal">
        <div class="bg-white px-3">
            <!-- <div class="close-modal mx-auto cr-pointer" /> -->
            <p class="f-500 f-14 text-center mt-2">Descargar nuestra app y compra tus productos favoritos.</p>
            <div class="row d-middle-center flex-wrap mt-3">
                <button class="not-now bg-white f-500 mx-2" @click="closeIt">
                    Ahora no
                </button>
                <button class="download bg-general f-500 mx-2" @click="goTo(type)">
                    Descargar
                </button>
            </div>
        </div>
    </vue-bottom-sheet>
</template>

<script>
export default {
    props: {
        type:{
            type:String,
            default: 'Android',
        },
    },
    data(){
        return {
            widthWindow:0,
        }
    },
    mounted(){
        this.handleResize()
        if (this.widthWindow < 576){
            this.$refs.mobileBottomSheet.open();
        }
    },
    methods:{
        goTo(type){
            let linkStore = null
            if (type === 'Android'){ linkStore = process.env.VUE_APP_URL_ANDROID }
            
            if (type === 'iOS'){ linkStore = process.env.VUE_APP_URL_APPLE }

            if(linkStore){
                window.open(linkStore, '_blank');
            }
        },
        closeIt(){
            this.$refs.mobileBottomSheet.close();
        },
        handleResize(){
            this.widthWindow = window.innerWidth;
        },
    }
}
</script>

<style lang="scss" scoped>

.mobile-modal{
	// min-height: 149px;
	// width: 100%;
	// z-index: 10 !important;
	// box-shadow: 0px 0px 6px #00000029;
	// border-radius: 24px 24px 0px 0px;
	// position: absolute;
    // bottom: 0px;
	//display: none;
	button{
		height: 44px;
		border-radius: 12px;
		min-width: 152px;
	}
	.not-now{
		border: 1px solid #DFE4E8;
		background: white;
	}
	.download{
		border: 1px solid transparent;
		color: white;
	}
	.close-modal{
		width: 34px;
		height: 4px;
		background: #DBDBDB;
		border-radius: 6px;
		margin-top: 4px;
	}
}
@media screen and (max-width: 576px){
	.mobile-modal{
		display: block !important;
	}
}
</style>